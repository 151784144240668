<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        :max-width="
          editedItem.bylist == '1' ||
          (editedItem.bylist == '0' && depot.NbRack > 0 && mvm_type != 1)
            ? '1600px'
            : '1000px'
        "
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>

            <v-spacer></v-spacer>
            <v-layout row justify-center align-center v-if="progress">
              <v-progress-circular
                indeterminate
                :size="30"
                :width="7"
                color="purple"
              ></v-progress-circular>
            </v-layout>

            <v-btn
              color="blue darken-1"
              @click="save"
              :disabled="save_disable"
              class="mx-2"
              small
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" @click.stop="close" small>
              Annuler
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  v-if="
                    (mvm_type == 3 || mvm_type == 23) &&
                    !allproduct &&
                    editedItem.id < 0 &&
                    !cmd_unique &&
                    !isloading
                  "
                >
                  <v-autocomplete
                    v-model="cmd_id"
                    :item-value="'cmd_id'"
                    :items="products"
                    :filter="customFilter"
                    label="Filtre par commande"
                    :readonly="!modify || form_list.length > 0"
                    clearable
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{ item.nocmd + " du " + datefr(item.date_cmd) }}
                    </template>
                    <template v-slot:item="{ item }">
                      {{ item.nocmd + " du " + datefr(item.date_cmd) }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="8"
                  v-if="(mvm_type == 3 || mvm_type == 23) && !allproduct"
                >
                  <v-autocomplete
                    v-model="editedItem.cmd_det_id"
                    :item-value="'id'"
                    :items="products_list"
                    :filter="customFilter"
                    label="Produit"
                    :readonly="!modify || form_list.length > 0"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    @change="produit_change"
                    dense
                    :loading="isloading"
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{ item.code + "-" + item.label }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list max-width="700">
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="item.code + '-' + item.label"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="'Ref. : ' + item.ref"
                            v-if="item.ref"
                          ></v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-html="
                              'Commande N° : ' +
                              item.nocmd +
                              ' du ' +
                              datefr(item.date_cmd)
                            "
                          ></v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-html="
                              'Qte Commandée : ' +
                              numberWithSpace(item.qte) +
                              ' ' +
                              (item.unit ? item.unit : '')
                            "
                          ></v-list-item-subtitle>

                          <v-list-item-subtitle>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                {{
                                  "Qte Recptionnée : " +
                                  numberWithSpace(item.qte_rcpt) +
                                  " " +
                                  (item.unit ? item.unit : "")
                                }}
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <v-progress-linear
                                  :value="item.prc_rcpt"
                                  color="light-green darken-2"
                                  height="25"
                                  rounded
                                >
                                  <template v-slot:default>
                                    {{ item.prc_rcpt + "% " }}
                                  </template>
                                </v-progress-linear>
                              </v-col>
                            </v-row>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                {{
                                  "Qte Livrée : " +
                                  numberWithSpace(item.qte_liv) +
                                  " " +
                                  (item.unit ? item.unit : "")
                                }}
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <v-progress-linear
                                  :value="item.prc_liv"
                                  color="light-green darken-2"
                                  height="25"
                                  rounded
                                >
                                  <template v-slot:default>
                                    {{ item.prc_liv + "% " }}
                                  </template>
                                </v-progress-linear>
                              </v-col>
                            </v-row>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            :class="
                              !item.stock_cmpt || item.stock_cmpt == 0
                                ? 'red'
                                : 'green'
                            "
                            v-html="
                              'Stock : ' +
                              (item.stock_cmpt
                                ? numberWithSpace(item.stock_cmpt)
                                : 0) +
                              ' ' +
                              (item.unit ? item.unit : '')
                            "
                            v-if="mvm_type != 23"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="10"
                  v-else-if="
                    ((mvm_type == 3 || mvm_type == 23) &&
                      !spec &&
                      allproduct) ||
                    mvm_type == 15 ||
                    mvm_type == 21
                  "
                >
                  <cursorselect
                    :Qsearch="products_cursor"
                    :Qresp="'products_cursor'"
                    :value="editedItem.produit_id"
                    :text_fields="['code', 'label']"
                    :variableadd="variableadd"
                    :whereadd="
                      mvm_type == 15 || mvm_type == 21 ? whereadd : null
                    "
                    @change="produit_change"
                    :label="'Produit'"
                    :key="cs"
                  >
                  </cursorselect>
                </v-col>

                <v-col cols="12" sm="6" md="10" v-else>
                  <v-autocomplete
                    v-model="editedItem.produit_id"
                    :item-value="
                      mvm_type == 3 || mvm_type == 23 ? 'id' : 'produit_id'
                    "
                    :items="products"
                    :filter="customFilter"
                    label="Produit"
                    :readonly="!modify || form_list.length > 0"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    @change="produit_change"
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (mvm_type == 7 ? item.product_code : item.code) +
                        "-" +
                        (mvm_type == 7 ? item.product_label : item.label)
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            (mvm_type == 7 ? item.product_code : item.code) +
                            '-' +
                            (mvm_type == 7 ? item.product_label : item.label)
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="'Ref. : ' + item.ref"
                          v-if="item.ref"
                        ></v-list-item-subtitle>
                        <v-list-item-subtitle
                          :class="
                            !item.stock_cmpt || item.stock_cmpt == 0
                              ? 'red'
                              : 'green'
                          "
                          v-html="
                            'Stock : ' +
                            (item.stock_cmpt ? item.stock_cmpt : 0) +
                            ' ' +
                            (item.unit ? item.unit : '')
                          "
                          v-if="mvm_type != 23"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                  v-if="
                    editedItem.produit_id &&
                    ((mvm_type != 3 && mvm_type != 21 && mvm_type != 23) ||
                      ((mvm_type == 3 || mvm_type == 23 || mvm_type == 21) &&
                        articles.length != 1)) &&
                    !price_correct
                  "
                >
                  <v-autocomplete
                    v-model="editedItem.article_id"
                    :item-value="article_field"
                    :items="articles"
                    :filter="customFilter"
                    label="Article"
                    :readonly="!modify || form_list.length > 0"
                    @change="article_change"
                    :rules="[(v) => !!v || 'Article obligatoire']"
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.code ? item.code + "-" : "") +
                        item.label +
                        " (" +
                        item.tier_name +
                        ")"
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            (item.code ? item.code + '-' : '') +
                            (item.ref_ext ? item.ref_ext + '-' : '') +
                            item.label
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="
                            'Stock : ' +
                            (item.stock_cmpt ? item.stock_cmpt : 0) +
                            ' ' +
                            (item.unit ? item.unit : '')
                          "
                          v-if="mvm_type != 23"
                        ></v-list-item-subtitle>

                        <v-list-item-action-text>
                          {{ "Fournisseur : " + item.tier_name }}
                        </v-list-item-action-text>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  v-if="
                    editedItem.article_id &&
                    (!editedItem.pu ||
                      $store.state.auth.includes('01027') ||
                      $store.state.isadmin)
                  "
                >
                  <v-text-field
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    v-model="editedItem.pu"
                    :readonly="
                      (iscmd ||
                        !(
                          $store.state.auth.includes('02027') ||
                          $store.state.isadmin
                        ) ||
                        !modify) &&
                      !price_correct
                    "
                    label="P.U."
                    :rules="[(v) => !!v || 'Prix unitaire obligatoire']"
                    dense
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="1"
                  md="1"
                  v-if="
                    editedItem.article_id &&
                    (mvm_type == 3 || mvm_type == 23) &&
                    ($store.state.auth.includes('02027') ||
                      $store.state.isadmin) &&
                    remise
                  "
                >
                  <v-text-field
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    v-model.number="editedItem.remise"
                    :readonly="
                      (iscmd ||
                        !(
                          $store.state.auth.includes('02027') ||
                          $store.state.isadmin
                        ) ||
                        !modify) &&
                      !price_correct
                    "
                    label="Remise(%)"
                    :rules="[
                      (v) =>
                        !v || (v > 0 && v <= 100) || 'Valeur entre 1 et 100',
                    ]"
                    dense
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  v-if="
                    editedItem.service == '0' &&
                    editedItem.article_id &&
                    mvm_type != 23 &&
                    !price_correct
                  "
                >
                  <v-autocomplete
                    ref="mvmfrom"
                    v-model="mvm_from"
                    item-value="depot_id"
                    :items="stock_depots"
                    :readonly="!modify || form_list.length > 0"
                    dense
                    label="Depot Source"
                    @change="depot_change"
                    :rules="[
                      (v) => !!v || 'Depot obligatoire',
                      (v) =>
                        ligne_exist(
                          iscmd ? editedItem.cmd_det_id : editedItem.article_id,
                          v
                        ) == false || 'Ligne existe!',
                    ]"
                    :loading="stock_load"
                  >
                    <template v-slot:selection="{ item }">
                      {{
                        item
                          ? item.depot_name +
                            " (" +
                            (item.stock_cmpt ? item.stock_cmpt : 0) +
                            " " +
                            (item.unit ? item.unit : "") +
                            ")"
                          : ""
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.depot_name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="
                            (item.stock_cmpt
                              ? item.stock_cmpt.toFixed(precision)
                              : 0) +
                            ' ' +
                            (item.unit ? item.unit : '')
                          "
                          v-if="mvm_type != 23"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  v-if="
                    editedItem.service == '0' &&
                    editedItem.article_id &&
                    mvm_type == 7 &&
                    !price_correct
                  "
                >
                  <v-select
                    ref="mvm_to"
                    v-model="mvm_to"
                    item-text="label"
                    :item-value="'id'"
                    :items="depots_list"
                    label="Depot de Destination"
                    :rules="[
                      (v) => !!v || 'Depot obligatoire',
                      (v) =>
                        v != editedItem.mvm_from ||
                        (v == editedItem.mvm_from && depot.NbRack > 0) ||
                        'Même Depot!',
                      (v) =>
                        ligne_exist(
                          editedItem.article_id,
                          editedItem.mvm_from,
                          v
                        ) == false || 'Ligne existe!',
                    ]"
                    @change="dest_change"
                    dense
                    :disabled="save_disable"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="2" md="2" v-if="mvm_to && mvm_type == 7">
                  <v-select
                    ref="machine"
                    v-model="editedItem.machine_id"
                    item-text="label"
                    item-value="id"
                    :items="machines"
                    label="Machine de Destination"
                    dense
                    clearable
                    :disabled="save_disable"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2" v-if="editedItem.article_id">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        autocomplete="off"
                        ref="qte"
                        class="inputPrice"
                        type="number"
                        dense
                        v-model.number="editedItem.qte"
                        :label="
                          'Quantité ' +
                          (editedItem.unit ? '(' + editedItem.unit + ')' : '')
                        "
                        :rules="[
                          (v) =>
                            !!v ||
                            'Quantité ' +
                              (editedItem.unit
                                ? '(' + editedItem.unit + ')'
                                : '') +
                              ' obligatoire',
                          (v) => parseFloat(v) > 0 || 'Valeur incorrecte ',
                          (v) =>
                            verif_max_depot(v) ||
                            !modify ||
                            mvm_type == 23 ||
                            'Stock depot ' + qte_depot,
                          (v) =>
                            parseFloat(v) <= parseFloat(qte_max) ||
                            mvm_type == 15 ||
                            mvm_type == 21 ||
                            mvm_type == 23 ||
                            (mvm_type == 3 && allproduct) ||
                            mvm_type == 7 ||
                            'Qte Max ' + qte_max,
                        ]"
                        :hint="
                          iscmd
                            ? 'Reste à Livrer ' +
                              (qte_rst - (editedItem.qte ? editedItem.qte : 0))
                            : null
                        "
                        v-bind="attrs"
                        v-on="on"
                        :readonly="
                          editedItem.bylist == '1' ||
                          (editedItem.bylist == '0' && depot.NbRack > 0) ||
                          !modify
                        "
                        @keydown.enter="save"
                        hide-spin-buttons
                        :disabled="
                          (!mvm_from && editedItem.service == '0') ||
                          save_disable
                        "
                        @focus="$event.target.select()"
                      ></v-text-field>
                    </template>
                    <li v-if="(mvm_type == 3 || mvm_type == 23) && !allproduct">
                      Quantité
                      {{
                        (mvm_type == 3 || mvm_type == 23
                          ? " commandée : "
                          : " reservée : ") + qte
                      }}
                    </li>
                    <li v-if="(mvm_type == 3 || mvm_type == 23) && !allproduct">
                      {{ "Quantité Livrée " + qte_liv }}
                    </li>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="1"
                  v-if="
                    editedItem.article_id &&
                    (!editedItem.pu ||
                      $store.state.auth.includes('01027') ||
                      $store.state.isadmin) &&
                    (mvm_type == 3 || mvm_type == 23) &&
                    mvm.country_id == 13
                  "
                >
                  <v-text-field
                    autocomplete="off"
                    ref="tva"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.tva_tx"
                    label="TVA"
                    :readonly="iscmd || !modify"
                    :rules="[
                      (v) => !v || parseFloat(v) > 0 || 'Valeur incorrecte',
                    ]"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="1"
                  md="2"
                  v-if="editedItem.is_moule == '1'"
                >
                  <v-checkbox
                    v-model="editedItem.destock"
                    label="Ne pas destocker"
                    class="ma-2"
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  sm="1"
                  md="2"
                  v-if="
                    editedItem.article_id && (mvm_type == 3 || mvm_type == 23)
                  "
                >
                  <v-text-field
                    autocomplete="off"
                    ref="ref_ext"
                    dense
                    v-model="editedItem.ref_ext"
                    label="Réf. Externe"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="1"
                  md="10"
                  v-if="
                    editedItem.article_id && (mvm_type == 3 || mvm_type == 23)
                  "
                >
                  <v-text-field
                    autocomplete="off"
                    ref="label"
                    dense
                    v-model="editedItem.label"
                    label="Désignation Externe"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="1"
                  md="12"
                  v-if="
                    editedItem.article_id && (mvm_type == 3 || mvm_type == 23)
                  "
                >
                  <v-text-field
                    autocomplete="off"
                    ref="description"
                    dense
                    v-model="editedItem.description"
                    label="Description Additionnelle"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="12">
                  <v-text-field
                    autocomplete="off"
                    ref="comment"
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="1"
                  md="6"
                  v-if="
                    (editedItem.bylist == '1' ||
                      (editedItem.bylist == '0' && depot.NbRack > 0)) &&
                    editedItem.article_id > 0 &&
                    mvm_from
                  "
                >
                  <v-row>
                    <v-col cols="12" sm="1" md="11">
                      <listitems
                        :list="flist"
                        :headers="
                          editedItem.bylist == '1'
                            ? dispo_headers
                            : boxheaders_d
                        "
                        :title="list_title"
                        :master="true"
                        :add="false"
                        :del="false"
                        :Update="false"
                        :showstd="true"
                        :showedit="true"
                        :Total="true"
                        :print="true"
                        :exp_excel="true"
                        :imp_excel="false"
                        :key="kflist"
                        :multiple="true"
                        @selected_rows="selected_left_change"
                        :laoding="productload"
                        @options="options_change"
                        @search="search_change"
                        :search_elm="search"
                        :ipg="ipg"
                      >
                      </listitems>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                      <v-btn
                        fab
                        color="blue darken-1"
                        @click="add_product"
                        class="my-2"
                        small
                        :disabled="selected_left.length == 0"
                      >
                        >
                      </v-btn>

                      <v-btn
                        fab
                        color="blue darken-1"
                        @click="del_product"
                        class="my-2"
                        small
                        :disabled="selected_right.length == 0"
                      >
                        <span v-html="'<'"></span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="6"
                  v-if="
                    (editedItem.bylist == '1' ||
                      (editedItem.bylist == '0' && depot.NbRack > 0)) &&
                    editedItem.article_id > 0 &&
                    mvm_from
                  "
                >
                  <listitems
                    :list="form_list"
                    :headers="
                      editedItem.bylist == '1' ? article_headers : boxheaders_s
                    "
                    :title="'Selected'"
                    :master="true"
                    :add="false"
                    :del="false"
                    :Update="true"
                    :showstd="true"
                    :Total="true"
                    :print="true"
                    :exp_excel="true"
                    :imp_excel="false"
                    :key="frml"
                    @delete="del_item"
                    @change="change_item"
                    :multiple="true"
                    @selected_rows="selected_right_change"
                    :laoding="listload"
                    @options="options_change"
                    :ipg="ipg"
                  >
                  </listitems>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-form>

    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
import MVM_DETAIL_UPDATE from "../graphql/Mouvment/MVM_DETAIL_UPDATE.gql";
import PRODUCT_LIST from "../graphql/Product/PRODUCT_LIST.gql";
import PRODUCT_LIST_MVM from "../graphql/Product/PRODUCT_LIST_MVM.gql";
import ARTICLE_DEPOTS from "../graphql/Mouvment/ARTICLE_DEPOTS.gql";
import PRODUCTS_CURSOR from "../graphql/Mouvment/PRODUCTS_CURSOR.gql";
import { remise } from "print/data.js";
import { spec, colisbl } from "print/data.js";
export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    listitems: () => import("./ListItems.vue"),
    cursorselect: () => import("./CursorSelect.vue"),
  },
  name: "mvmlivclientform",
  props: {
    item: Object,
    items: Array,
    showForm: Boolean,
    cmddetails: Array,
    mvm_type: Number,
    attributes: Array,
    client_products: Array,
    mvm: Object,
    modify: Boolean,
    price_correct: Boolean,
    units: Array,
    areg: { type: Boolean, default: false },
    isloading: Boolean,
  },
  data: () => ({
    kflist: 100,
    allproduct: false,
    adding: false,
    deleting: false,
    productload: false,
    save_disable: false,
    imp: false,
    title: "Liste Détaillée",
    list_title: "Disponible",
    listload: false,
    isListClose: true,
    list: false,
    datepicker1: false,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    ipg: 5,
    search: "",
    defaultItem: {
      id: -1,
      service: 0,
    },
    editedItem: {},
    combovalue: "",
    isProductClosed: true,
    product: {},
    depot: {},
    qte: 0,
    qte_liv: 0,
    qte_ret: 0,
    qte_rst: 0,
    qte_max: 0,
    qte_min: 0,
    qte_depot: 0,
    tol: 0,
    cmd_id: null,
    mvm_from: "",
    mvm_to: "",
    depot_id: "",
    precision: 2,
    dispo_headers_o: [
      {
        text: "No",
        value: "no",
        selected: true,
      },

      {
        text: "Code",
        value: "ref",
        selected: true,
      },
      {
        text: "Article",
        value: "label",
        selected: true,
        hiden: true,
      },
      {
        text: "Quantité",
        value: "qte_stock",
        total: "qte_stock",
        slot: "cur",
        align: "end",
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
      {
        text: "Date de péremption",
        value: "expire_date",
        selected: true,
        hiden: true,
        slot: "date",
      },
      {
        text: "Origine",
        value: "origin",
        selected: true,
      },
      {
        text: "Box",
        value: "box",
        selected: true,
        hiden: true,
      },
      {
        text: "commande",
        value: "cmd_name",
        selected: true,
        hiden: true,
      },
      {
        text: "Palette",
        value: "palette",
        selected: true,
      },
      {
        text: "Comment",
        value: "comment",
        selected: true,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
    ],
    article_headers_o: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },

      {
        text: "Code",
        value: "ref",
        selected: true,
        edit: true,
        type: "text",
        sm: 6,
        md: 6,
        rules: [(v) => !!v || "Code obligatoire"],
      },
      {
        text: "Article",
        value: "label",
        selected: true,
        hiden: true,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "href22",
        align: "end",
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
      {
        text: "Date de péremption",
        value: "expire_date",
        selected: true,
        hiden: true,
        slot: "date",
        edit: true,
        type: "date",
        date_max: null,
        dense: false,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Date de péremption obligatoire"],
      },
      {
        text: "Origine",
        value: "origin",
        selected: true,
      },
      {
        text: "Box",
        value: "box",
        selected: true,
        hiden: true,
      },
      {
        text: "Palette",
        value: "palette",
        selected: true,
      },
      {
        text: "Comment",
        value: "comment",
        selected: true,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
    ],
    dispo_headers: [],
    article_headers: [],
    boxheaders_d: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Rack",
        value: "rack",
        selected: true,
      },
      {
        text: "Box",
        value: "box",
        selected: true,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "cur",
        align: "end",
        selected: true,
      },
    ],
    boxheaders_s: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Rack",
        value: "rack",
        selected: true,
      },
      {
        text: "Box",
        value: "box",
        selected: true,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "href22",
        align: "end",
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
    ],
    article_field: null,
    stock_load: false,
    form_list: [],
    prdslist: [],
    selected_left: [],
    selected_right: [],
    stock_depots: [],
    stock_boxs: [],
    articles: [],
    machines: [],
    frml: 600,
    cs: 0,
  }),

  computed: {
    depots_list() {
      let l = this.$store.state.depots.filter(
        (elm) => elm.ChildCount == 0 && elm.type_id == 3
      );
      return l;
    },
    cmd_unique() {
      var unique = this.cmddetails.map((elm) => elm.cmd_id).filter(onlyUnique);
      return unique.length == 1;
    },
    products_cursor() {
      return PRODUCTS_CURSOR;
    },
    variableadd() {
      let w = {
        Kind: this.mvm_type == 15 ? 34 : 3,
        statut_id: [2],
      };
      return w;
    },
    whereadd() {
      return [
        { column: "STOCK_CMPT", operator: "GT", value: "0" },
        { column: "SERVICE", value: this.mvm_type == 23 ? 1 : 0 },
      ];
    },

    spec() {
      return spec;
    },
    iscmd() {
      return (this.mvm_type == 3 || this.mvm_type == 23) && !this.allproduct;
    },

    remise() {
      return remise;
    },

    flist() {
      let l = [];
      if (this.editedItem.bylist == "1") {
        if (this.prdslist)
          l = this.prdslist.filter(
            (elm) => !this.form_list.map((elm3) => elm3.id).includes(elm.id)
          );
      } else {
        if (this.stock_boxs)
          l = this.stock_boxs.filter(
            (elm) =>
              !this.form_list.map((elm3) => elm3.box_id).includes(elm.box_id)
          );
      }

      return l;
    },
    sumlist_selected() {
      let list = [];
      list = this.form_list ? this.form_list : [];
      let v_min = 0;
      if (this.articles_link)
        if (this.articles_link.length > 0) {
          let l = [];
          this.articles_link.forEach((element) => {
            let v =
              list
                .filter((elm) => elm.article_id == element.link_id)
                .reduce((a, b) => a + (b["qte"] || 0), 0) / element.ratio;
            l.push(v / element.ratio);
          });
          v_min = Math.min(...l);
        } else v_min = list.reduce((a, b) => a + (b["qte"] || 0), 0);
      return parseFloat(v_min.toFixed(this.precision));
    },

    attributs_list() {
      return this.attributes.filter((elm) => elm.type_attr == "colis");
    },
    colis_unit() {
      let c = "item";
      let i = this.units.findIndex(
        (elm) => elm.id == this.editedItem.fk_unit_colis
      );
      if (i >= 0) c = this.units[i].name;
      return c;
    },
    products() {
      let list = [];
      if (this.iscmd) list = this.cmddetails;

      if (
        (this.mvm_type == 3 || this.mvm_type == 23) &&
        this.spec &&
        this.allproduct
      ) {
        list = this.client_products;
      }
      if (this.mvm_type == 7) {
        if (this.mvm.orders)
          this.mvm.orders.forEach((element) => {
            if (element.boms)
              element.boms.forEach((bom) => {
                let k = list.findIndex(
                  (elm) => elm.article_id == bom.article_id
                );
                if (k >= 0) {
                  list[k].qte = parseFloat(list[k].qte) + bom.qte;
                } else list.push(bom);
              });
          });
      }

      return list;
    },

    products_list() {
      let l = [];
      if (this.products) {
        l = this.products;
        if (this.cmd_id)
          l = this.products.filter((elm) => elm.cmd_id == this.cmd_id);
      }
      return l;
    },
    articles_link() {
      let l = [];
      if (this.product)
        if (this.product.articles) {
          let i = this.product.articles.findIndex(
            (elm) =>
              (this.mvm_type == 3 ||
              this.mvm_type == 23 ||
              this.mvm_type == 15 ||
              this.mvm_type == 21
                ? elm.id
                : elm.article_id) == this.editedItem.article_id
          );
          if (i >= 0) {
            if (this.product.articles[i].links)
              l = this.product.articles[i].links;
          }
        }
      return l;
    },

    showDetail() {
      return this.showForm;
    },
    articles_list() {
      let list = [];
      if (this.articles) {
        list = this.articles.filter(
          (elm) =>
            !this.items
              .map((elm2) => elm2.article_id)
              .includes(elm[this.article_field]) || this.editedItem.id > 0
        );
      }
      return list;
    },
    depots() {
      return this.$store.state.depots.filter((elm) => elm.ChildCount == 0);
    },
    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
  },
  watch: {
    form_list: {
      handler() {
        if (
          this.editedItem.bylist == "1" ||
          (this.editedItem.bylist == "0" && this.depot.NbRack > 0)
        ) {
          if (this.editedItem.id < 0)
            this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
          if (
            colisbl &&
            this.editedItem.bylist == "1" &&
            this.editedItem.id < 0
          ) {
            this.editedItem.description = "";
            this.editedItem.colis_json = [];
            if (this.form_list) {
              this.form_list.forEach((element) => {
                let i = this.editedItem.colis_json.findIndex(
                  (elm) =>
                    elm.article_id == element.article_id &&
                    elm.qte == element.qte
                );
                if (i >= 0)
                  this.editedItem.colis_json[i].nb =
                    this.editedItem.colis_json[i].nb + 1;
                else
                  this.editedItem.colis_json.push({
                    article_id: element.article_id,
                    label: element.label,
                    qte: element.qte,
                    nb: 1,
                  });
              });
            }
            this.editedItem.description = this.get_desc(
              this.editedItem.colis_json,
              this.editedItem.qte,
              this.colis_unit,
              this.editedItem.unit
            );
          }
        }
      },
      deep: true,
    },
  },

  async created() {
    this.allproduct =
      (!this.spec && this.mvm_type != 7) ||
      (this.areg && (this.mvm_type == 3 || this.mvm_type == 23));
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);

        this.cs++;
        this.mvm_to = this.editedItem.depot_id;
        this.mvm_from = this.editedItem.mvm_from;
        if (this.editedItem.bylist == "1") await this.list_mvm();
        if (this.spec && (this.mvm_type == 3 || this.mvm_type == 23)) {
          let i = this.client_products.findIndex(
            (elm) => elm.produit_id == this.editedItem.produit_id
          );
          if (i == -1) this.allproduct = true;
        }
        this.dest_change();
        this.produit_change();
        this.depot_change();
      }
    }
  },

  methods: {
    dest_change() {
      let i = this.depots_list.findIndex((elm) => elm.id == this.mvm_to);
      if (i >= 0) {
        this.machines = this.depots_list[i].machines;
      }
    },
    get_desc(list, qte, colis_unit, unit) {
      let desc = "";
      if (list)
        if (list.length > 0) {
          var unique = list.map((elm) => elm.article_id).filter(onlyUnique);
          if (unique.length > 1) {
            unique.forEach((elm2) => {
              let l = list.filter((elm) => elm.article_id == elm2);
              if (l.length > 0) {
                desc = desc + " " + l[0].label + "\n";
                list
                  .filter((elm) => elm.article_id == l[0].article_id)
                  .forEach((element) => {
                    desc =
                      desc +
                      element.nb.toString() +
                      " " +
                      colis_unit +
                      (element.nb > 1 ? "s" : "") +
                      " / " +
                      element.qte +
                      " " +
                      unit +
                      " " +
                      "\n";
                  });
              }
            });
          } else
            list.forEach((element) => {
              desc =
                desc +
                element.nb.toString() +
                " " +
                colis_unit +
                (element.nb > 1 ? "s" : "") +
                " / " +
                element.qte +
                " " +
                unit +
                " " +
                "\n";
            });
          if (list.length > 0 && unique.length == 1)
            desc =
              desc +
              "Soit " +
              list.reduce((a, b) => a + (b["nb"] || 0), 0) +
              " " +
              colis_unit +
              "s pour un total de " +
              qte +
              " " +
              unit;
        }
      return desc;
    },
    datefr(date, time = false, long = true) {
      let t = "";
      let option = {
        year: "numeric",
        month: long ? "long" : "numeric",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    verifie_double() {
      let ok = true;
      for (let index = 0; index < this.form_list.length; index++) {
        const element = this.form_list[index];
        let list2 = this.form_list.filter(
          (elm) => elm.ref == element.ref && elm.id != element.id
        );
        if (list2.length > 0) {
          this.snackbar_text = "Code " + element.ref + " dupliqué";
          this.snackbar_color = "error";
          this.snackbar = true;
          ok = false;
          break;
        }
      }

      return ok;
    },
    set_articles(list) {
      this.articles = [];
      if (this.product) {
        let i;
        if (this.iscmd) {
          i = list.findIndex(
            (elm) =>
              elm.produit_id == this.editedItem.produit_id &&
              elm.article_id == null
          );
          if (i >= 0) {
            this.article_field = "id";
            if (this.product.articles)
              this.articles = this.product.articles.filter(
                (elm) => elm.stock_cmpt > 0 || this.product.service == 1
              );
          } else {
            this.article_field = "article_id";
            this.articles = list.filter(
              (elm) => elm.id == this.editedItem.cmd_det_id
            );
          }
        } else {
          this.article_field = this.mvm_type == 7 ? "article_id" : "id";

          this.articles = list.filter(
            (elm) => elm.produit_id == this.editedItem.produit_id
          );
          if (
            this.mvm_type == 3 ||
            this.mvm_type == 23 ||
            this.mvm_type == 15 ||
            this.mvm_type == 21
          ) {
            let i = list.findIndex(
              (elm) => elm.id == this.editedItem.produit_id
            );

            if (i >= 0)
              if (list[i].articles)
                this.articles = list[i].articles.filter(
                  (elm) => elm.stock_cmpt > 0 || this.product.service == 1
                );
          }
        }
      }
    },
    async verif_amalgame() {
      let ok = true;
      let a = "";
      if (this.articles_link)
        this.articles_link.forEach((element) => {
          let v = this.form_list
            .filter((elm) => elm.article_id == element.link_id)
            .reduce((a, b) => a + (b["qte"] || 0), 0);
          let d = v / element.ratio - this.editedItem.qte;
          if (d > 0) {
            ok = false;
            a = a + "Article : " + element.label + " Quantité : " + d + "\n";
          }
        });
      if (a.length > 0) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Attention Excédent de Produits !! Etês-vous sûr ?",
            "",
            a
          )
        )
          ok = true;
      }
      return ok;
    },
    customFilter(item, queryText) {
      let textlist = [];
      [
        "ref",
        "code",
        "label",
        "product_label",
        "product_code",
        "ref_ext",
        "box",
        "rack",
        "nocmd",
        "date_cmd",
      ].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    async depot_change() {
      if (this.editedItem.id < 0) {
        this.editedItem.qte = 0;
        this.form_list = [];
      }
      let i = this.depots.findIndex((elm) => elm.id == this.mvm_from);

      if (i >= 0) {
        this.depot = this.depots[i];
        let j = this.stock_depots.findIndex(
          (elm) => elm.depot_id == this.mvm_from
        );
        if (j >= 0 && this.articles_link.length == 0) {
          this.stock_boxs = this.stock_depots[j].stock_boxs.filter(
            (elm) => elm.depot_id == this.mvm_from
          );
          this.kflist++;
        }
        this.list_title =
          this.editedItem.bylist == "1" ? "Disponible" : this.depot.box;
        this.dispo_headers_o[6].text = this.depot.box ? this.depot.box : "Box";
        this.dispo_headers_o[6].hiden = this.depot.NbRack == 0;
        this.article_headers_o[6].text = this.depot.box
          ? this.depot.box
          : "Box";
        this.article_headers_o[6].hiden = this.depot.NbRack == 0;
        let u = "";
        let z;
        if (this.units)
          z = this.units.findIndex((elm) => elm.id == this.editedItem.fk_unit);
        if (z >= 0) u = "(" + this.units[z].name + ")";
        this.boxheaders_d[1].text = this.depot.rack ? this.depot.rack : "Rack";
        this.boxheaders_s[1].text = this.depot.rack ? this.depot.rack : "Rack";
        this.boxheaders_d[2].text = this.depot.box ? this.depot.box : "Box";
        this.boxheaders_s[2].text = this.depot.box ? this.depot.box : "Box";
        this.boxheaders_d[3].text = "Quantité " + u;
        this.boxheaders_d[3].dec = this.precision;
        this.boxheaders_s[3].text = "Quantité " + u;
        this.boxheaders_s[3].dec = this.precision;
        if (this.editedItem.bylist == "1") this.init_list();
        if (
          this.editedItem.bylist == "0" &&
          this.depot.NbRack > 0 &&
          this.editedItem.id > 0
        ) {
          this.form_list = this.editedItem.boxs;
          this.frml++;
        }
      }
      //this.$refs.form.resetValidation();
      this.frml++;

      if (this.editedItem.bylist == "1" && this.modify) {
        await this.load_product_list();
      } else if (this.$refs.qte) this.$refs.qte.focus();
    },
    options_change(option) {
      this.ipg = option.itemsPerPage;
    },
    search_change(search) {
      this.search = search;
      let i = this.flist.findIndex((elm) => elm.ref == search);
      if (i >= 0) {
        this.form_list.unshift(this.flist[i]);
        this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
        this.search = null;
        this.frml++;
        this.kflist++;
      }
    },
    selected_left_change(items) {
      this.selected_left = items;
    },
    selected_right_change(items) {
      this.selected_right = items;
    },
    add_product() {
      if (this.selected_left.length > 0) {
        this.selected_right = [];
        this.selected_left.forEach((element) => {
          element.selected = false;
          this.form_list.unshift(element);
        });
        this.selected_left = [];
        this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
        this.frml++;
        this.kflist++;
      }
    },

    del_product() {
      if (this.selected_right) {
        this.selected_left = [];
        this.selected_right.forEach((element) => {
          element.selected = false;
          this.form_list.splice(
            this.form_list.findIndex((elm) => elm.id == element.id),
            1
          );
        });
        this.selected_right = [];
        this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
        this.frml++;
        this.kflist++;
      }
    },
    del_item() {
      this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
    },
    change_item(item) {
      item.changed = true;
    },
    numberWithSpace: function (x) {
      return x != null
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "";
    },
    init_list() {
      let u = "";
      let z;
      if (this.units)
        z = this.units.findIndex((elm) => elm.id == this.editedItem.fk_unit);
      if (z >= 0) u = "(" + this.units[z].name + ")";
      this.dispo_headers = [];
      this.dispo_headers_o.forEach((element) => {
        this.dispo_headers.push(element);
      });
      this.article_headers = [];
      this.article_headers_o.forEach((element) => {
        this.article_headers.push(element);
      });
      this.dispo_headers[2].hiden = this.articles_link.length == 0;
      this.article_headers[2].hiden = this.articles_link.length == 0;

      this.dispo_headers[3].text = "Quantité " + u;
      this.dispo_headers[3].rules = [(v) => !!v || "Quantité obligatoire"];
      this.dispo_headers[3].dec = this.precision;
      this.dispo_headers[4].hiden = this.editedItem.is_expire == "0";

      this.article_headers[4].hiden = this.editedItem.is_expire == "0";
      this.article_headers[4].edit = this.editedItem.is_expire == "1";
      this.article_headers[3].text = "Quantité " + u;
      this.article_headers[3].rules = [(v) => !!v || "Quantité obligatoire"];
      this.article_headers[3].dec = this.precision;
      this.article_headers[3].value = "qte";
      this.article_headers[3].total = "qte";
      this.article_headers[3].readonly = this.editedItem.partial == "0";
      this.article_headers[3].slot = "href22";

      let colis_attributs = [];
      colis_attributs = this.editedItem.colisattributs
        ? typeof this.editedItem.colisattributs == "string"
          ? this.editedItem.colisattributs.split(",")
          : this.editedItem.colisattributs
        : [];

      for (let index = 0; index < colis_attributs.length; index++) {
        const element = colis_attributs[index];

        let i = this.attributs_list.findIndex((elm) => elm.id == element);
        if (i >= 0) {
          let ls = [];
          this.attributs_list[i].attributvalues.forEach((element) => {
            ls.push(element.name);
          });
          let elm = {
            text: this.attributs_list[i].caption
              ? this.attributs_list[i].caption
              : this.attributs_list[i].name,
            value: "ref" + index,
            selected: true,
            edit: true,
            readonly: false,
            rules: [
              (v) =>
                !!v ||
                (this.attributs_list[i].caption
                  ? this.attributs_list[i].caption
                  : this.attributs_list[i].name) + " obligatoire",
            ],

            key: this.attributs_list[i].key,
            type:
              this.attributs_list[i].attributvalues.length > 0
                ? "autocomplete"
                : "text",
            select_list: ls,
            show_list: ls,
            sm: 6,
            md: 6,
          };
          this.dispo_headers.splice(index + 3, 0, elm);
          this.article_headers.splice(index + 3, 0, elm);
        }
      }
    },
    async load_product_list() {
      //list possible

      this.productload = true;
      let v;
      let r;
      if (
        this.mvm_from &&
        (this.articles_link.length > 0 || this.editedItem.article_id)
      ) {
        v = {
          article_id:
            this.articles_link.length > 0
              ? this.articles_link.map((elm) => elm.link_id)
              : [this.editedItem.article_id],
          depot_id: this.mvm_from,
          stock_id: 1,
          mvm_id: this.mvm.id,
        };
        this.qte_min = 0;
        r = await this.requette(PRODUCT_LIST, v);
        if (r) {
          this.prdslist = r.product_list;

          this.prdslist.sort(this.sortBy("qte"));
          for (let index = 0; index < this.prdslist.length; index++) {
            const element = this.prdslist[index];
            this.qte_min = this.qte_min + element.qte;
            if (this.qte_min >= this.qte_max) break;
          }

          if (
            this.qte_max > 0 &&
            this.qte_min > 0 &&
            this.qte_min > this.qte_max &&
            this.mvm_type != 3
          )
            this.qte_max = this.qte_min;
        }
        this.productload = false;
        this.kflist++;
      }
    },
    async list_mvm() {
      this.form_list = [];

      if (this.editedItem.id >= 0) {
        this.listload = true;
        let v = {
          mvm_detail_id: this.editedItem.id,
        };

        let r = await this.requette(PRODUCT_LIST_MVM, v);
        if (r) {
          this.form_list = r.product_list_mvm;
          this.frml++;
          if (this.editedItem.id < 0)
            this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
          this.listload = false;
          //this.$refs.form.resetValidation();
        }
      }
    },
    sortBy(prop) {
      return function (a, b) {
        return a[prop] > b[prop] ? 1 : -1;
      };
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = { ok: true, data: data.data };
          this.progress = false;
        })
        .catch((error) => {
          r = { ok: false, error: error };
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async requette(query, v) {
      this.loading = true;
      let r = [];

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    verif_max_depot(v) {
      // verif max depot
      let i;
      let ok = true;
      if (this.editedItem.article_id && this.mvm_from) {
        ok = false;

        i = this.stock_depots.findIndex((elm) => elm.depot_id == this.mvm_from);
        if (i >= 0) {
          let qte_tmp = 0;
          let list = this.items.filter((elm) => elm.id != this.editedItem.id);

          list.forEach((element) => {
            if (
              element.article_id == this.editedItem.article_id &&
              element.depot_id == this.mvm_from
            )
              qte_tmp = qte_tmp + parseFloat(element.qte);
          });

          this.qte_depot = this.stock_depots[i].stock_cmpt - qte_tmp;
          if (v <= this.qte_depot) ok = true;
        }
      }
      return ok;
    },

    ligne_exist(p, d) {
      let i = this.items.filter((elm) => elm.id != this.item.id);
      let e = -1;
      if (p && d) {
        e = i.findIndex(
          (elm) =>
            (this.iscmd ? elm.cmd_det_id : elm.article_id) == p &&
            elm.depot_id == d
        );
      }
      return e != -1;
    },

    calc_max_cmd() {
      let i;
      let qte_tmp = 0;
      let tol = 0;
      if (this.mvm_type == 3 || this.mvm_type == 23)
        this.items.forEach((element) => {
          if (
            element.cmd_det_id == this.editedItem.cmd_det_id &&
            element.id != this.editedItem.id
          )
            qte_tmp = qte_tmp + parseFloat(element.qte);
        });
      if (this.mvm_type == 7)
        if (this.items)
          this.items.forEach((element) => {
            if (
              element.article_id == this.editedItem.article_id &&
              element.id != this.editedItem.id
            )
              qte_tmp = qte_tmp + parseFloat(element.qte);
          });
      if (this.articles)
        i = this.articles.findIndex(
          (elm) => elm[this.article_field] == this.editedItem.article_id
        );
      if (i >= 0) {
        this.editedItem.pmp = this.articles[i].pmp;

        if (this.mvm_type != 3 && this.mvm_type != 23 && this.editedItem.id < 0)
          this.editedItem.pu = this.editedItem.pmp;

        if (this.mvm_type == 7) {
          this.qte = this.articles[i].qte;
          this.qte_liv = this.articles[i].qte_liv;
          this.qte_ret = this.articles[i].qte_ret;
          this.editedItem.unit_name = this.articles[i].unit_name;
          this.editedItem.unit = this.articles[i].unit;
          this.editedItem.bylist = this.articles[i].bylist;
          this.editedItem.partial = this.articles[i].partial;
          this.precision = this.articles[i].virgule;

          tol = this.articles[i].tol ? this.articles[i].tol : 0;
          this.editedItem.colisattributs = this.articles[i].colisattributs;
          this.tol = (this.qte * tol) / 100;
        }
      }
      this.qte_rst = parseFloat(
        (this.qte - this.qte_liv - qte_tmp).toFixed(this.precision)
      );

      this.qte_max =
        this.qte -
        this.qte_liv +
        this.tol -
        qte_tmp +
        (this.editedItem.qte ? this.editedItem.qte : 0);
      this.qte_max = this.qte_max > 0 ? this.qte_max : 0;
    },
    produit_change(item, l) {
      let list = this.products;

      if (l) {
        list = l;
        this.editedItem.produit_id = item;
      }
      if (this.editedItem.id < 0) {
        this.mvm_from = null;
        this.editedItem.pu = null;
      }
      let i = list.findIndex(
        (elm) =>
          (this.mvm_type == 15 ||
          this.mvm_type == 3 ||
          this.mvm_type == 23 ||
          this.mvm_type == 21
            ? elm.id
            : elm.produit_id) ==
          (this.iscmd ? this.editedItem.cmd_det_id : this.editedItem.produit_id)
      );

      if (i >= 0) {
        this.product = list[i];
        this.editedItem.bylist = list[i].bylist;
        this.editedItem.is_moule = list[i].is_moule;
        if (this.item.id < 0 && this.editedItem.is_moule == "1")
          this.editedItem.destock = true;
        this.editedItem.partial = list[i].partial;
        this.editedItem.factor = list[i].factor ? list[i].factor : 1;
        this.editedItem.product_factor = list[i].factor;
        this.editedItem.service = list[i].service;
        if (this.iscmd) {
          this.editedItem.produit_id = list[i].produit_id;
          this.editedItem.pu = list[i].pu;
          this.editedItem.fk_unit = list[i].fk_unit;
          this.editedItem.is_expire = list[i].is_expire;
          this.editedItem.fk_unit_colis = list[i].fk_unit_colis;
          this.editedItem.tva_tx = list[i].tva_tx;

          this.qte = list[i].qte ? list[i].qte : 0;
          if (this.editedItem.id < 0 && this.editedItem.bylist == "0")
            this.editedItem.qte = this.qte - list[i].qte_liv;
          this.qte_liv = list[i].qte_liv ? list[i].qte_liv : 0;
          this.qte_ret = list[i].qte_ret ? list[i].qte_ret : 0;
          let tol = 0;

          if (this.mvm_type == 3 || this.mvm_type == 23)
            tol = list[i].tol_qte ? list[i].tol_qte : 0;
          this.tol = (this.qte * tol) / 100;
        } else {
          if (
            (this.mvm_type == 3 || this.mvm_type == 23) &&
            this.allproduct &&
            this.editedItem.id < 0
          ) {
            this.editedItem.pu = list[i].prixvente;
          }

          if (this.editedItem.id < 0) this.editedItem.fk_unit = list[i].fk_unit;
          this.editedItem.fk_unit_colis = list[i].fk_unit_colis;
          this.editedItem.fk_unit_buy = list[i].fk_unit_buy;
          this.editedItem.fk_unit_product = list[i].fk_unit;
          this.editedItem.is_expire = list[i].is_expire;
          if (list[i].service == 1 && !this.editedItem.tva_tx)
            this.editedItem.tva_tx =
              this.$store.state.options[0].default_service_tva;
          if (list[i].service == 0 && !this.editedItem.tva_tx)
            this.editedItem.tva_tx =
              this.$store.state.options[0].default_product_tva;
        }
        this.editedItem.gamme_id = list[i].gamme_id;
        this.editedItem.ref_ext = list[i].ref_ext;
        this.editedItem.tobuy = list[i].tobuy;

        this.precision = list[i].decimal;
        this.editedItem.colisattributs =
          this.mvm_type == 15 || this.mvm_type == 21
            ? list[i].tabcolisattributs
            : list[i].colisattributs;
        this.editedItem.unit_name = list[i].unit_name;
        this.editedItem.unit = list[i].unit;
        this.editedItem.tomanufacture = list[i].tomanufacture;
      }
      this.set_articles(list);
      if (this.articles) {
        let l = this.articles.filter(
          (elm) => elm.stock_cmpt > 0 || this.editedItem.service == 1
        );
        if ((l.length == 1 && this.item.id < 0) || this.item.id > 0) {
          if (this.item.id < 0)
            this.editedItem.article_id = l[0][this.article_field];
          this.article_change();
        }
      }
    },
    async article_change() {
      this.stock_depots = [];
      this.prdslist = [];

      if (this.editedItem.id < 0) {
        this.form_list = [];
        this.editedItem.qte = 0;
        this.mvm_from = null;
      }
      let v = {
        where: {
          AND: [
            {
              column: "ID",
              operator: "IN",
              value: [this.editedItem.article_id],
            },
            {
              column: "STOCK_CMPT",
              operator: "GT",
              value: 0,
            },
          ],
        },
      };
      this.stock_load = true;
      let r = await this.requette(ARTICLE_DEPOTS, v);
      this.stock_load = false;
      if (r) {
        if (r.article_depots.length > 0)
          this.stock_depots =
            this.articles_link.length > 0
              ? r.article_depots[0].stock_amalgame.filter(
                  (elm) => elm.type_id == 2
                )
              : r.article_depots[0].stock_depots.filter(
                  (elm) => elm.type_id == 2
                );
        if (this.stock_depots.length == 1 && this.editedItem.id < 0) {
          this.mvm_from = this.stock_depots[0].depot_id;
          this.depot_change();
        }

        await this.calc_max_cmd();
        if (this.$refs.form) this.$refs.form.resetValidation();
      }
      if (this.editedItem.bylist == "0" && this.$refs.qte)
        this.$refs.qte.focus();
    },

    async close() {
      this.$emit("close", this.items);
    },
    verifie_attribut() {
      let ok = true;
      let l = this.article_headers.filter(
        (elm) => elm.edit == true && elm.hiden != true
      );
      for (let index = 0; index < this.form_list.length; index++) {
        const element = this.form_list[index];

        for (let i = 0; i < l.length; i++) {
          if (!element[l[i].value] && l[i].value != "comment") {
            this.snackbar_text =
              l[i].text + " manquant! Ligne " + (parseInt(index) + 1);
            this.snackbar_color = "error";
            this.snackbar = true;
            ok = false;
            break;
          }
        }

        if (this.editedItem.is_expire == "1" && !element.expire_date) {
          this.snackbar_text =
            "Date péremption manquante! Ligne " + (parseInt(index) + 1);
          this.snackbar_color = "error";
          this.snackbar = true;
          ok = false;
          break;
        }
      }
      return ok;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let ok = true;

        //verfier code liste
        if (
          this.editedItem.bylist == "1" &&
          (this.mvm_type == 7 || this.mvm_type == 15 || this.mvm_type == 21)
        ) {
          let j = this.form_list.findIndex(
            (elm) => elm.ref == null || elm.ref == ""
          );
          if (j >= 0) {
            this.snackbar_text =
              "Code article N° " + parseInt(j + 1) + " manquant!!--verifier";
            this.snackbar_color = "error";
            this.snackbar = true;
            ok = false;
          }
          if (ok) ok = this.verifie_double();
        }
        //if (this.editedItem.bylist == "1" && ok) ok = this.verifie_attribut();
        //verifier produit nc
        if (this.mvm_type == 3 && ok) {
          let i = this.depots.findIndex((elm) => elm.id == this.mvm_from);
          if (i >= 0) {
            if (this.depots[i].conforme == "1") {
              ok = false;
              if (
                await this.$refs.confirm.open(
                  "Confirmation",
                  "Produit Non Conforme!! Etês-vous sûr ?"
                )
              )
                ok = true;
            }
          }
        }
        if (this.articles_link.length > 0 && ok)
          ok = await this.verif_amalgame();
        if (ok) {
          this.save_disable = true;
          let products_list = [];
          let articles_link = [];
          let pl = [];
          let boxs = [];
          let elm = {};
          this.articles_link.forEach((element) => {
            articles_link.push({
              link_id: element.link_id,
              ratio: element.ratio,
            });
          });
          if (this.editedItem.bylist == "1") {
            this.form_list.forEach((element) => {
              elm = {
                fk_produit_list: element.id,
                article_id: element.article_id,
                machine_id: this.editedItem.machine_id,
                qte: element.qte,
              };
              if (element.changed) {
                pl.push({
                  id: element.id,
                  ref: element.ref,
                  ref0: element.ref0,
                  ref1: element.ref1,
                  ref2: element.ref2,
                  ref3: element.ref3,
                  ref4: element.ref4,
                  ref5: element.ref5,
                });
              }
              products_list.push(elm);
            });
          }
          if (this.editedItem.bylist == "0" && this.depot.NbRack > 0) {
            for (let index = 0; index < this.form_list.length; index++) {
              const element = this.form_list[index];
              let elm = {};
              elm.article_id = this.editedItem.article_id;
              elm.box_id = element.box_id;
              elm.qte = element.qte;

              boxs.push(elm);
              //
            }
          }
          let i;
          i = this.articles.findIndex(
            (elm) => elm.id == this.editedItem.article_id
          );
          if (i >= 0) {
            this.editedItem.product_name = this.articles[i].label;
          }
          let v;
          v = {
            mvmdetail: [
              {
                mvm_id: this.item.mvm_id,
                pu: parseFloat(this.editedItem.pu),
                qte: parseFloat(this.editedItem.qte),
                tva_tx: this.editedItem.tva_tx,
                fk_unit: this.editedItem.fk_unit,
                remise: parseFloat(this.editedItem.remise),
                label:
                  this.mvm_type == 3 || this.mvm_type == 23
                    ? this.editedItem.label
                    : null,
                description: this.editedItem.description,
                colis_json: this.editedItem.colis_json
                  ? JSON.stringify(this.editedItem.colis_json)
                  : null,
                gamme_id: this.editedItem.gamme_id,
                depot_id:
                  this.mvm_type == 7
                    ? this.mvm_to
                    : this.editedItem.service == "0"
                    ? this.mvm_from
                    : null,
                machine_id: this.editedItem.machine_id,
                mvm_from: this.mvm_type == 7 ? this.mvm_from : null,
                article_id: this.editedItem.article_id,
                cmd_det_id: this.editedItem.cmd_det_id,
                destock: this.editedItem.destock ? 1 : 0,
                comment: this.editedItem.comment,
                write_uid: this.$store.state.me.id,
              },
            ],
            mvm_type: this.mvm_type,
            id: this.editedItem.id > 0 ? this.editedItem.id : null,
            products_list: products_list.length > 0 ? products_list : null,
            pl: pl.length > 0 ? pl : null,
            boxs: boxs.length > 0 ? boxs : null,
            price_correct: this.price_correct,
            articles_link: articles_link,
            precision: this.precision,
          };

          let r = await this.maj(MVM_DETAIL_UPDATE, v);
          if (r.ok) {
            if (this.editedItem.id > 0)
              this.items.splice(this.item.index, 1, this.editedItem);
            else {
              this.editedItem.id = r.UpdateMvmDetail;
              this.items.push(this.editedItem);
            }
            this.$emit("change", this.editedItem);
            this.$store.dispatch("Changed", true);
            this.$emit("close", this.items);
          } else {
            let s = r.error.graphQLErrors[0].debugMessage;
            if (s.search("1062") > 0 && s.search("products_list_index03") > 0) {
              this.snackbar_text =
                "Code " +
                (this.product.colis_unit ? this.product.colis_unit : "item") +
                " existe déja";
            } else this.snackbar_text = "Erreur d'enregistrement inconnue ";
            this.snackbar_color = "error";
            this.snackbar = true;
            this.save_disable = false;
          }
        }
        this.$emit("change", this.editedItem);
      }
    },
  },
};
</script>
